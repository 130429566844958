<template>
  <!-- v-if will prevent event.title from causing error -->
  <!-- due to api call not yet returning its response  -->
  <div v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
    <i>({{ event.id }})</i>
  </div>
</template>

<script>
import EventService from '@/services/EventService';

export default {
  // receive id props from EventCard
  props: ['id'],
  data() {
    return {
      event: null,
      // id: 123,
    };
  },
  // creation of component
  // fetch event (by id) and set local event data
  created() {
    // 1. after route is clicked (in EventCard) and component is "created" (in EventDetails),
    // api call will fetch data for single event by id
    // 2. once json response is received for that event,
    // local component's (EventDetails) event data will be set ("event")
    EventService.getEvent(this.id)
      .then((response) => {
        this.event = response.data;
        // the callback to exeute when the Promise is returned
        // console.log('events:', response.data);
      })
      .catch((error) => {
        // the callback to execute when the Promise is rejected
        console.log(error);
      });
  },
};
</script>
