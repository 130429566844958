import axios from 'axios';

// single axios instance for our ENTIRE app
const apiClient = axios.create({
  // base url for all calls to use
  baseURL: 'https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3',
  withCredentials: false,
  headers: {
    // authentication and configuration
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// retrieves the added on portion of the base url, in this case, the "events"
// export getEvents method
export default {
  getEvents() {
    return apiClient.get('/events');
  },
  getEvent(id) {
    // returning event by id
    // return apiClient.get('/events/' + id)
    return apiClient.get(`/events/${id}`);
  },
};
