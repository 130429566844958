import { createRouter, createWebHistory } from 'vue-router';
// importing components from views dir
import EventList from '@/views/EventList.vue';
import EventDetails from '@/views/EventDetails.vue';
import About from '@/views/About.vue';

const routes = [
  {
    // url for this route
    path: '/',
    // name of the route
    name: 'EventList',
    // which view component to render
    component: EventList,
  },
  {
    // path: '/event/123',
    // :id dynamic segment
    path: '/event/:id',
    // name of the route
    name: 'EventDetails',
    // sending in route params as component props
    props: true,
    // which view component to render
    component: EventDetails,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    // // route level code-splitting - a performance optimization
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

// History property
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
