<template>
 <h1>Events for Good</h1>
  <div class="event-list">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <event-card v-for="event in events" :key="event.id" :event="event"/>
  </div>
</template>

<script>
// import axios from 'axios';
import EventCard from '@/components/EventCard.vue';
// no longer need to import axios, as it's in EventService.js
import EventService from '@/services/EventService';

export default {
  name: 'EventList',
  components: {
    EventCard,
  },
  data() {
    return {
      // replace data with events: null
      events: null,
    };
  },
  // created vue component lifecycle hook - ...calling out to event when component is created
  created() {
    // EventService.js is the dedicated location where all api calls can happen, vs in each
    // component which would mean each component is creating a new Axios instance
    // once "created", api call will fetch events data and set local data ("events")
    EventService.getEvents()
      // axios is proms-based and synchronous, so we have to wait for a response before continuing
      .then((response) => {
        this.events = response.data;
        // the callback to exeute when the Promise is returned
        // console.log('events:', response.data);
      })
      .catch((error) => {
        // the callback to execute when the Promise is rejected
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
  .event-list {
    display:flex;
    align-items: center;
    flex-direction: column;
  }
</style>
