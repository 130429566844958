<template>
  <!-- router link wraps all text making it a link -->
  <!-- added name of route we want to route to and pass id as props to EventDetails -->
  <!-- i.e. giving EventDetails access to params as a prop -->
  <!-- added params property to pass id as props -->
  <router-link
    class="event-link"
    :to="{ name: 'EventDetails', params: { id: event.id } }"
  >
    <div class="event-card">
      <span>@{{ event.time }} on {{ event.date }}</span>
      <h4>{{ event.title }}</h4>
      <p>
        <i>Organizer: {{ event.organizer }}</i>
      </p>
      <i>Event ID: {{ event.id }}</i>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'EventCard',
  // event object passed in as props
  // we have access to things like id from this passed in event prop
  // hence, we can use it in params above
  props: {
    // event: Object,
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}

.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  // transition-delay:.1s;
}

.event-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
